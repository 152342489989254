import React, { useRef, useState } from 'react'
import AddDriver from './AddDriver';
import AddHelper from './AddHelper';
import AddINC from './AddINC';
import AddVehicle from './AddVehicle';
import EditDriver from './EditDriver';
import EditHelper from './EditHelper';
import EditInc from './EditInc';
import EditVehicle from './EditVehicle';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Resources = ({ ward, driver, inc, helper, vehicle, token, baseUrl }) => {

    const navigate = useNavigate()

    const [editDriverData, seteditDriverData] = useState({ is_leave: '', is_blocked: '', ward_id: '', driver_id: '' })
    const [editHelperData, seteditHelperData] = useState({ is_leave: '', is_blocked: '', ward_id: '', helper_id: '' })
    const [editIncData, seteditIncData] = useState({ is_leave: '', is_blocked: '', ward_id: '', inc_id: '' })
    const [editVehicleData, seteditVehicleData] = useState({ name: '', number: '', ward_id: '', helper_id: '' })


    const refOpenDriver = useRef(null);
    const refOpenHelper = useRef(null);
    const refOpenVehicle = useRef(null);
    const refOpenINC = useRef(null);
    const refDriverEditOpen = useRef(null);
    const refHelperEditOpen = useRef(null);
    const refIncEditOpen = useRef(null);
    const refVehicleEditOpen = useRef(null);


    const editDriver = (driver) => {
        seteditDriverData({ is_leave: driver.is_leave, is_blocked: driver.is_blocked, ward_id: driver.p_ward_id, driver_id: driver.p_id })
        refDriverEditOpen.current.click();
    }

    const editHelper = (helper) => {
        seteditHelperData({ is_leave: helper.h_leave, is_blocked: helper.h_blocked, ward_id: helper.h_ward_id, helper_id: helper.helper_id })
        refHelperEditOpen.current.click();
    }

    const editInc = (Inc) => {
        seteditIncData({ is_leave: Inc.inc_leave, is_blocked: Inc.inc_blocked, ward_id: Inc.inc_ward_id, inc_id: Inc.inc_id })
        refIncEditOpen.current.click();
    }

    const editVehicle = (Vehicle) => {
        seteditVehicleData({ vehicle_name: Vehicle.vehicle_name, vehicle_number: Vehicle.vehicle_number, vehicle_type: Vehicle.vehicle_type, v_ward_id: Vehicle.v_ward_id, vehicle_id: Vehicle.vehicle_id })
        refVehicleEditOpen.current.click();
    }

    const deleteHelper = async (helperId, helperName) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: `Are You Sure You Want to Delete the Helper ${helperName}.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel',
            });

            if (!result.isConfirmed) {
                console.log('Helper deletion cancelled by the user.', helperId, helperName);
                return;
            }

            const response = await fetch(`https://apis.ecowrap.in/b2g/internal-team/api/v1/helper/${helperId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                Swal.fire({
                    title: 'Deleted!',
                    text: 'The helper has been deleted successfully.',
                    icon: 'success',
                });
                window.location.reload()
            } else {
                console.error('Failed to delete driver:', response.status, response.statusText);
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to delete the helper. Please try again later.',
                    icon: 'error',
                });
            }
        } catch (error) {
            console.error('Error while deleting driver:', error);
            Swal.fire({
                title: 'Error!',
                text: 'An unexpected error occurred. Please try again later.',
                icon: 'error',
            });
        }
    };

    const deleteDriver = async (driverId, driverName) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: `Are You Sure You Want to Delete the Driver ${driverName}.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel',
            });

            if (!result.isConfirmed) {
                console.log('Driver deletion cancelled by the user.', driverId, driverName);
                return;
            }

            const response = await fetch(`https://apis.ecowrap.in/b2g/internal-team/api/v1/driver/${driverId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                Swal.fire({
                    title: 'Deleted!',
                    text: 'The driver has been deleted successfully.',
                    icon: 'success',
                });
                window.location.reload()
            } else {
                console.error('Failed to delete driver:', response.status, response.statusText);
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to delete the driver. Please try again later.',
                    icon: 'error',
                });
            }
        } catch (error) {
            console.error('Error while deleting driver:', error);
            Swal.fire({
                title: 'Error!',
                text: 'An unexpected error occurred. Please try again later.',
                icon: 'error',
            });
        }
    };

    const deleteINC = async (INCId, INCName) => {
        console.log('INCId', INCId);

        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: `Are You Sure You Want to Delete the INC ${INCName}.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel',
            });

            if (!result.isConfirmed) {
                console.log('INC deletion cancelled by the user.', INCId, INCName);
                return;
            }

            const response = await fetch(`https://apis.ecowrap.in/b2g/internal-team/api/v1/inc/${INCId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                Swal.fire({
                    title: 'Deleted!',
                    text: 'The INC has been deleted successfully.',
                    icon: 'success',
                });
                window.location.reload()
            } else {
                console.error('Failed to delete INC:', response.status, response.statusText);
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to delete the INC. Please try again later.',
                    icon: 'error',
                });
            }
        } catch (error) {
            console.error('Error while deleting INC:', error);
            Swal.fire({
                title: 'Error!',
                text: 'An unexpected error occurred. Please try again later.',
                icon: 'error',
            });
        }
    };


    const handleLogout = () => {
        localStorage.removeItem('sprvsrtkn');
        navigate('/login');
    };


    return (
        <div className='container-fluid'>
            <nav className="d-flex flex-row justify-content-between align-items-center">
                <div className="navbar-brand-home">
                    <img src='asset/ecowrap-logo.png' alt="Logo" />
                </div>
                <div className='col-0'>
                    <button className='btn btn-secondary' onClick={handleLogout}>Logout</button>
                </div>
            </nav>

            <main className='home-main mt-4'>
                <div className='d-flex justify-content-between  mb-4'>
                    <button className='btn btn-secondary rounded-circle  ' style={{ fontSize: '24px' }} onClick={() => navigate('/')}>&larr;</button>
                </div>
                <div className='driver-data'>
                    <h1>Drivers</h1>
                    <div className='w-100 text-end'>
                        <AddDriver ward={ward} refOpen={refOpenDriver} token={token} />
                        <button className='btn btn-secondary' onClick={() => { refOpenDriver.current.click() }}>Add driver </button>
                    </div>

                    <table className="table table-borderless h-auto table-striped table-hover">
                        <thead>
                            <tr>
                                <th >#</th>
                                <th > Name</th>
                                <th >Number</th>
                                <th >Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {driver.length > 0 ? (
                                driver.map((driver, index) => (
                                    <tr key={index}>
                                        <th >{index + 1}</th>
                                        <th >{driver?.p_name}</th>
                                        <th >{driver?.p_number}</th>

                                        <td>
                                            <button className='btn btn-sm btn-success me-2' onClick={() => editDriver(driver)}><i class="fa fa-pencil"></i></button>
                                            <button className='btn btn-sm btn-danger' onClick={() => deleteDriver(driver?.p_id, driver?.p_name)} ><i class="fa fa-trash"></i></button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10">No Driver available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                </div>

                <div className='helper-data'>
                    <h1>Helper</h1>
                    <div className='w-100 text-end'>
                        <AddHelper ward={ward} refOpen={refOpenHelper} token={token} />
                        <button className='btn btn-secondary' onClick={() => { refOpenHelper.current.click() }}>Add Helper </button>
                    </div>
                    <div className='table-container'>
                        <table className='table table-striped table-hover'>
                            <thead>
                                <tr>
                                    <th >#</th>
                                    <th > Name</th>
                                    <th >Number</th>
                                    <th >Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {helper.length > 0 ? (
                                    helper.map((helper, index) => (
                                        <tr key={index}>
                                            <th >{index + 1}</th>
                                            <th >{helper?.helper_name}</th>
                                            <th >{helper?.helper_number}</th>

                                            <td>
                                                <button onClick={() => editHelper(helper)} className='btn btn-sm btn-success me-2'><i class="fa fa-pencil"></i></button>
                                                <button className='btn btn-sm btn-danger' onClick={() => deleteHelper(helper?.helper_id, helper?.helper_name)} ><i class="fa fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="10">No Driver available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='inc-data'>
                    <h1>INC Person</h1>
                    <div className='w-100 text-end'>
                        <AddINC ward={ward} refOpen={refOpenINC} token={token} />
                        <button className='btn btn-secondary' onClick={() => { refOpenINC.current.click() }}>Add INC </button>
                    </div>

                    <div className='table-container'>
                        <table className='table table-striped table-hover'>
                            <thead>
                                <tr>
                                    <th >#</th>
                                    <th > Name</th>
                                    <th >Number</th>
                                    <th >Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {inc.length > 0 ? (
                                    inc.map((inc, index) => (
                                        <tr key={index}>
                                            <th >{index + 1}</th>
                                            <th >{inc?.inc_name}</th>
                                            <th >{inc?.inc_number}</th>

                                            <td>
                                                <button onClick={() => editInc(inc)} className='btn btn-sm btn-success me-2'  ><i class="fa fa-pencil"></i></button>
                                                <button onClick={() => deleteINC(inc?.inc_id, inc?.inc_name)} className='btn btn-sm btn-danger' ><i class="fa fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="10">No Driver available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='vehicle-data'>
                    <h1>Vehicles</h1>
                    <div className='w-100 text-end'>
                        <AddVehicle ward={ward} refOpen={refOpenVehicle} token={token} />
                        <button className='btn btn-secondary' onClick={() => { refOpenVehicle.current.click() }}>Add Vehicle </button>
                    </div>

                    <div className='table-container'>
                        <table className='table table-striped table-hover'>
                            <thead>
                                <tr>
                                    <th >#</th>
                                    <th > Name</th>
                                    <th >Number</th>
                                    {/* <th >Type</th> */}
                                    <th >Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vehicle.length > 0 ? (
                                    vehicle.map((vehicle, index) => (
                                        <tr key={index}>
                                            <th >{index + 1}</th>
                                            <th >{vehicle?.vehicle_name}</th>
                                            <th >{vehicle?.vehicle_number}</th>
                                            {/* <th >{vehicle?.vehicle_type}</th> */}

                                            <td>
                                                <button onClick={() => editVehicle(vehicle)} className='btn btn-sm btn-success me-2'><i class="fa fa-pencil"></i></button>
                                                {/* <button className='btn btn-sm btn-danger'><i class="fa fa-trash"></i></button> */}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="10">No Driver available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>

            <EditDriver ward={ward} refOpen={refDriverEditOpen} editDriverData={editDriverData} seteditDriverData={seteditDriverData} token={token} baseUrl={baseUrl} />
            <EditHelper ward={ward} refOpen={refHelperEditOpen} editHelperData={editHelperData} seteditHelperData={seteditHelperData} token={token} baseUrl={baseUrl} />
            <EditInc ward={ward} refOpen={refIncEditOpen} editIncData={editIncData} seteditIncData={seteditIncData} token={token} baseUrl={baseUrl} />
            <EditVehicle ward={ward} refOpen={refVehicleEditOpen} editVehicleData={editVehicleData} seteditVehicleData={seteditVehicleData} token={token} baseUrl={baseUrl} />

        </div>
    )
}

export default Resources